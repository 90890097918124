const CustomRadio = ({ className = '', ...props }) => {
  return (
    <input
      className={`custom-radio ${className ? className : ''}`}
      type="radio"
      {...props}
    />
  );
};

export default CustomRadio;
