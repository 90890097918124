// styling for this component is in emiCardPayment.scss

import { getEmiOptionsSpecificToCard, initPayment, setEmiOptions } from '@/redux/actions';
import { cardNumberFormat, getCardType, isCardValid } from '@/utils/cardTypeValidator';
import { cc_icons } from '@/utils/constants';
import { debounce } from '@/utils/debounce';
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config, { CURRENCY_SYMBOL } from 'src/config';
import { useRouter } from 'next/router';
import { getEncrypDecryptStr } from '@/utils/encryptionDecryption';
import { getDeviceInfo } from '@/utils/deviceInfo';
import { CARD_TYPE, PAYMENT_TYPE_CONSTANTS } from '@/utils/constants';
import { BackArrow } from '@/assets/svgExports/BackArrow';
import CustomRadio from '../CustomRadio';
import { toastNotifyError } from '../Common/Toast';

const rangeOfYears = () => {
  const result = [];
  let min = new Date().getUTCFullYear();
  const max = min + 20;
  while (min <= max) {
    result.push('' + min);
    min++;
  }
  return result;
};

const EmiCardPayment = ({ isDesktop = true }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [emiInformation, setEmiInformation] = useState({
    cardInformation: {
      cardNumber: '',
      monthSelected: '01',
      yearSelected: rangeOfYears()[0],
      cvv: '',
    },
    cvvLength: 3,
    selectedEmi: {},
    step: 1,
    completedSteps: [],
    cardType: '',
    emiAvailable: true,
  });
  const [openEmiOption, setOpenEmiOption] = useState(0);
  const [cardValidation, setCardValidation] = useState({
    isCardNumberValid: true,
    isCardCVVInvalid: false,
  });
  const [showCvvShowcase, setShowCvvShowcase] = useState(false);

  const { emiOptionsForCard, userData, orderDetails, store } = useSelector((state) => ({
    store: state.storeReducer.store,
    orderDetails: state.orderReducer.orderDetails,
    emiOptionsForCard: state.emiReducer.emiOptionsForCard,
    userData: state.userReducer,
  }));

  const toggleCvvPopup = () => {
    setShowCvvShowcase((s) => !s);
  };

  const inputChange = (value, type) => {
    const tempState = {
      ...emiInformation,
      cardInformation: { ...emiInformation.cardInformation },
    };
    const tempCardValidation = { ...cardValidation };
    switch (type) {
      case 'cardNo': {
        tempCardValidation.isCardNumberValid = true;
        if (value.length <= 20) {
          tempState.cardInformation.cardNumber = cardNumberFormat(value.trim());
          tempState.cardType = getCardType(value.trim());
          tempState.emiAvailable = true;
          tempState.cvvLength =
            getCardType(tempState.cardInformation.cardNumber) === CARD_TYPE.AMEX ? 4 : 3;
          debounceOnChange(value.trim());
        }
        break;
      }
      case 'cardMonth': {
        tempState.cardInformation.monthSelected = value;
        break;
      }
      case 'cardYear': {
        tempState.cardInformation.yearSelected = value;
        break;
      }
      case 'cvv': {
        tempCardValidation.isCardCVVInvalid = false;
        if (value.length <= emiInformation.cvvLength) {
          tempState.cardInformation.cvv = value;
        }
      }
      default:
        break;
    }
    setEmiInformation((state) => ({
      ...state,
      ...tempState,
    }));
    setCardValidation((state) => ({
      ...state,
      ...tempCardValidation,
    }));
  };

  const validateCardNumber = (number) => {
    const newState = { ...cardValidation };
    newState['isCardNumberValid'] = isCardValid(number);
    setCardValidation(newState);
  };

  const validateCardCVV = (number) => {
    const newState = { ...cardValidation };
    newState['isCardCVVInvalid'] =
      number.length !== emiInformation.cvvLength ? 'Enter valid CVV' : false;
    setCardValidation(newState);
  };

  const validateAll = () => {
    const newState = { ...cardValidation };
    let isAllValid = true;
    if (!emiInformation.cardInformation.cardNumber) {
      newState['isCardNumberValid'] = false;
      isAllValid = false;
    }
    if (emiInformation.cvvLength !== emiInformation.cardInformation.cvv.length) {
      newState['isCardCVVInvalid'] = 'Enter a valid CVV';
      isAllValid = false;
    }
    setCardValidation(newState);
    return isAllValid;
  };

  const handleCardNumberChange = (value) => {
    if (value.length >= 6) {
      const callback = (emiOptionAvailable) => {
        setEmiInformation((state) => ({
          ...state,
          emiAvailable: emiOptionAvailable,
        }));
      };
      /**
       * change card_number in obj to allow only the first 6 digits
       * in card number to be sent as payload for initiatePayment api
       */
      dispatch(
        getEmiOptionsSpecificToCard(
          {
            store_id: store?.store_id,
            hash: orderDetails?.order_hash,
            card_number: value.substring(0, 6),
          },
          callback
        )
      );
    }
  };

  const debounceOnChange = useCallback(debounce(handleCardNumberChange, 500), []);

  const completeEmiSteps = (step) => {
    if (
      !emiInformation.emiAvailable ||
      emiInformation.cardInformation.cardNumber === '' ||
      emiInformation.cardInformation.cardNumber.split(' ').join('').length < 14
    )
      return;
    const tempCompletedSteps = [...emiInformation.completedSteps];
    tempCompletedSteps.push(step);
    const newStepCount = emiInformation.step === 3 ? 3 : emiInformation.step + 1;

    setEmiInformation((state) => ({
      ...state,
      completedSteps: [...new Set(tempCompletedSteps)],
      step: newStepCount,
    }));
  };

  const editEmiSteps = (step) => {
    setEmiInformation((state) => ({
      ...state,
      completedSteps: step === 1 ? [] : [1],
      step: step,
    }));
  };

  const selectEmiPlanBtnClick = (emiPlan) => {
    setEmiInformation((state) => ({
      ...state,
      selectedEmi: emiPlan,
    }));
    const payload = {
      order_hash: orderDetails?.order_hash,
      store_id: store?.store_id,
      emi_option: { ...emiPlan },
    };

    const callback = (status) => {
      if (status) {
        completeEmiSteps(2);
      } else {
        toastNotifyError('Cannot select emi plan currently');
      }
    };
    dispatch(setEmiOptions(payload, callback));
  };

  const emiStepCompletionForMobile = () => {
    switch (emiInformation.step) {
      case 1:
        completeEmiSteps(1);
        break;
      case 3:
        completeEmiPaymentInit();
        break;
      default:
        break;
    }
  };

  const disabilityOfMobileButton = () => {
    switch (emiInformation.step) {
      case 1:
        return (
          !(emiInformation.cardInformation.cardNumber.length >= 16) ||
          !emiInformation.emiAvailable
        );
      case 3:
        return emiInformation.cardInformation.cvv.length !== emiInformation.cvvLength;
      default:
        return true;
    }
  };

  const CompletedStepComponent = ({ children }) => (
    <div className="emi-step-completed">{children}</div>
  );

  const completeEmiPaymentInit = () => {
    if (validateAll()) {
      const data = {
        orderID: orderDetails?.order_hash,
        paymentMethod: PAYMENT_TYPE_CONSTANTS.EMI,
        merchantID: orderDetails?.merchant_id,
        deviceType: getDeviceInfo().OSName,
        browserType: getDeviceInfo().BrowserName,
        userAgent: getDeviceInfo().userAgent,
        source: 'longtail',
        cNum: emiInformation.cardInformation.cardNumber.split(' ').join(''),
        cCvv: emiInformation.cardInformation.cvv,
        cName: 'DOTPE',
        cExpMonth: emiInformation.cardInformation.monthSelected,
        cExpYear: emiInformation.cardInformation.yearSelected,
        merchantName: `Digital Showroom - ${store?.store_info?.name}`,
        emiCode: emiInformation.selectedEmi.emi_code,
        phone: userData?.data?.phone,
      };
      const encryptCCDetails = getEncrypDecryptStr(JSON.stringify(data), 'encrypt');
      localStorage?.setItem('dscc', JSON.stringify(encryptCCDetails));
      dispatch(initPayment(data, router));
    }
  };

  const cardBaseErrorMessage = () => {
    if (!emiInformation.emiAvailable) {
      return (
        <small className="error">
          No EMI option available for this Bank, please try different card
        </small>
      );
    }
    if (!cardValidation.isCardNumberValid) {
      return <small className="error">Enter valid card number</small>;
    }
    return null;
  };

  const affirmationButton = ({
    onClick = null,
    disabled = false,
    label = 'Continue',
    showButton = true,
  }) => {
    if (!showButton) return null;

    return (
      <button
        disabled={disabled}
        className={`pointer dib affirmation-btn fw6-ns fw5 br-pill ${
          disabled ? 'o-30' : ''
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };

  const EditButton = ({ onClick = undefined, label = '', customStyle = {} }) => (
    <span
      className={`relative pointer dib edit-btn br-pill`}
      onClick={onClick}
      style={customStyle}
    >
      {label ?? 'Edit'}
    </span>
  );

  /**
   * Function to change all characters in the card number
   * after the 6th digit into 'X', 1st step is completed
   * @return {string} morphed car number
   */
  const cardNumberRenderAfterStep1 = () => {
    // here slice starts at 7th inex due to 1 space between card number blocks
    const firstSixChar = emiInformation.cardInformation.cardNumber.substring(0, 7);
    const mappedLastChars = emiInformation.cardInformation.cardNumber.slice(7).split(' ');
    const morphString = mappedLastChars.map((value) => value.replace(/./gi, 'X'));
    return `${firstSixChar}${morphString.join(' ')}`;
  };

  const cardInformationSection = () => {
    return (
      <div className="mb4-ns">
        <h3 className="emiCardPayment__header">Enter Credit or Debit card details</h3>
        <div
          className={`card-information-section flex ${
            isDesktop ? 'flex-row' : 'flex-column'
          }`}
        >
          <div className="card-information-section--first">
            <div className="flex items-center justify-between">
              <div className="card-header-text">Card Number</div>
              {!isDesktop && emiInformation.completedSteps.includes(1) && (
                <span className="card-header-edit dib" onClick={() => editEmiSteps(1)}>
                  Edit Card
                </span>
              )}
            </div>
            <div className="relative">
              {emiInformation.completedSteps.includes(1) ? (
                <CompletedStepComponent>
                  <p className="mv0">{cardNumberRenderAfterStep1()}</p>
                </CompletedStepComponent>
              ) : (
                <>
                  <input
                    type="tel"
                    className={`emi-input w-100 ${
                      !emiInformation.emiAvailable || !cardValidation.isCardNumberValid
                        ? 'invalid'
                        : ''
                    }`}
                    value={emiInformation.cardInformation.cardNumber}
                    placeholder="XXXX XXXX XXXX XXXX"
                    onChange={(e) =>
                      inputChange(e.target.value.replace(/[^0-9]/g, ''), 'cardNo')
                    }
                    onBlur={(e) =>
                      validateCardNumber(e.target.value.replace(/[^0-9]/g, ''))
                    }
                  />
                  {cardBaseErrorMessage()}
                </>
              )}
              {emiInformation?.cardType ? (
                <img
                  className="card-image"
                  loading="lazy"
                  src={
                    cc_icons[emiInformation.cardType] ||
                    config.CDN_SERVER_URL + 'image/ic-credit-card.svg'
                  }
                  alt="cardType"
                />
              ) : null}
            </div>
          </div>
          <div className="card-information-section--second flex flex-column justify-end">
            {emiInformation.completedSteps.includes(1) ? (
              isDesktop && (
                <EditButton
                  label="Edit Card"
                  customStyle={{ bottom: 8 }}
                  onClick={() => editEmiSteps(1)}
                />
              )
            ) : (
              <>
                <div className="card-header-text">Expiry Date</div>
                <div className="flex items-center">
                  <span className="relative mr3">
                    <select
                      value={emiInformation.cardInformation.monthSelected}
                      className="emi-select"
                      onChange={(e) => inputChange(e.target.value, 'cardMonth')}
                    >
                      {Array.from(Array(12).keys()).map((arr) => (
                        <option key={arr}>{`${+arr + 1 < 10 ? '0' : ''}${
                          arr + 1
                        }`}</option>
                      ))}
                    </select>
                    <img alt="dropdown button" src="/assets/images/down-arrow-thin.svg" />
                  </span>
                  <span className="relative">
                    <select
                      value={emiInformation.cardInformation.yearSelected}
                      className="emi-select"
                      onChange={(e) => inputChange(e.target.value, 'cardYear')}
                    >
                      {rangeOfYears()?.map((range) => (
                        <option key={range}>{range}</option>
                      ))}
                    </select>
                    <img alt="dropdown button" src="/assets/images/down-arrow-thin.svg" />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        {(!emiInformation.completedSteps.includes(1) || emiInformation.step === 1) && (
          <div className="flex justify-end" style={{ marginTop: 25 }}>
            {affirmationButton({
              showButton: isDesktop,
              disabled:
                !(emiInformation.cardInformation.cardNumber.length >= 16) ||
                !emiInformation.emiAvailable,
              onClick: () => completeEmiSteps(1),
            })}
          </div>
        )}
      </div>
    );
  };

  const emiSelectionComponent = () => {
    return (
      <div className="emi-selection-section mb4-ns">
        <div className="flex items-center justify-between">
          <h3 className="emiCardPayment__header">Select EMI Tenure</h3>
          {!isDesktop && emiInformation.completedSteps.includes(2) && (
            <span className="card-header-edit dib" onClick={() => editEmiSteps(2)}>
              Edit Tenure
            </span>
          )}
        </div>
        {emiInformation.completedSteps.includes(2) ? (
          <div className="flex items-center">
            <div className="card-information-section--first">
              <CompletedStepComponent>
                <p className="mv0">
                  <b>
                    {CURRENCY_SYMBOL}
                    {emiInformation?.selectedEmi?.payable_emi_value || ''}
                  </b>{' '}
                  X {emiInformation?.selectedEmi?.tenure} | Total Cost - {CURRENCY_SYMBOL}
                  {emiInformation?.selectedEmi?.total_payable_amount}
                </p>
              </CompletedStepComponent>
            </div>
            {isDesktop && (
              <div
                className="card-information-section--second flex flex-column justify-end"
                style={{
                  paddingLeft: 0,
                }}
              >
                <EditButton label="Edit Tenure" onClick={() => editEmiSteps(2)} />
              </div>
            )}
          </div>
        ) : (
          <div
            className="overflow-hidden"
            style={{
              border: '0.4px solid #D6D6D6',
              borderRadius: 7,
            }}
          >
            {emiOptionsForCard?.length > 0 &&
              emiOptionsForCard?.map((emi, index) => (
                <div className="emi-option-accordion" key={index}>
                  <div
                    className="emi-option-accordion__header flex items-center pointer"
                    onClick={() => setOpenEmiOption(index)}
                  >
                    <CustomRadio
                      readOnly
                      className="mr3 ultramarine-blue-color"
                      type="radio"
                      checked={openEmiOption === index}
                      onChange={undefined}
                    />
                    <p className="mv0">
                      <b className="fw6">
                        {CURRENCY_SYMBOL}
                        {emi.payable_emi_value}
                      </b>{' '}
                      X {emi.tenure} | Total Cost - {CURRENCY_SYMBOL}
                      {emi.total_payable_amount}
                    </p>
                  </div>
                  {openEmiOption === index && (
                    <div className="emi-option-accordion__body">
                      <table className="emi-table w-100">
                        <thead>
                          <tr>
                            <th>{isDesktop ? 'EMI per month' : 'EMI Plan'}</th>
                            <th>{isDesktop ? 'Interest' : 'Interest(pa)'}</th>
                            <th>{isDesktop ? 'Total EMI Cost' : 'Total Cost'}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b className="fw6">
                                {CURRENCY_SYMBOL}
                                {emi.payable_emi_value}
                              </b>
                            </td>
                            <td>
                              {CURRENCY_SYMBOL}
                              {emi.total_emi_interest_paid}({emi.emi_bank_interest}%)
                            </td>
                            <td>
                              {CURRENCY_SYMBOL}
                              {emi.total_payable_amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="tr-ns tc pt24px">
                        {affirmationButton({
                          label: 'Select EMI Plan',
                          onClick: () => selectEmiPlanBtnClick(emi),
                        })}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  };

  const cvvShowcasePopup = () => (
    <div className="cvvShowcasePopup z-5">
      <p>The CVV number is the last three digits at the back of your card.</p>
      <img src="/assets/images/cvv-showcase-mobile.png" />
    </div>
  );

  const checkForValidCVV = () =>
    emiInformation.cardInformation.cvv.length !== 0 && cardValidation.isCardCVVInvalid;

  const cardCvvInput = () => {
    return (
      <div className="mb4 relative">
        <h3 className="emiCardPayment__header relative flex items-center">
          Enter CVV{' '}
          <img
            src="/assets/images/info-icon-grey.svg"
            onMouseOver={toggleCvvPopup}
            onMouseOut={toggleCvvPopup}
          />
        </h3>
        {showCvvShowcase && cvvShowcasePopup()}
        <div className="flex items-center relative">
          <input
            type="tel"
            className={`emi-input mr3 w-20-ns w-40 ${
              checkForValidCVV() ? 'invalid' : ''
            }`}
            value={emiInformation.cardInformation.cvv}
            onChange={(e) => inputChange(e.target.value.replace(/[^0-9]/g, ''), 'cvv')}
            onBlur={(e) => validateCardCVV(e.target.value)}
          />
          {checkForValidCVV() && (
            <small className="error">{cardValidation.isCardCVVInvalid}</small>
          )}
          {affirmationButton({
            showButton: isDesktop,
            disabled:
              emiInformation.cardInformation.cvv.length !== emiInformation.cvvLength,
            onClick: completeEmiPaymentInit,
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="emiCardPayment">
      {!isDesktop && (
        <div className="page-header flex items-center">
          <BackArrow onClick={() => router.back()} />
          <span className="header-text ml3 fw6">EMI</span>
        </div>
      )}
      <div className="emiCardPayment__body">
        {/* card information section */}
        {cardInformationSection()}
        {/* EMI selection */}
        {emiInformation.step >= 2 && emiSelectionComponent()}
        {/* Enter cvv */}
        {emiInformation.step === 3 && cardCvvInput()}
      </div>
      {/* button to show only in mobile and for steps 1 and 3 */}
      {!isDesktop && emiInformation.step !== 2 && (
        <div className="mobile-affirm-btn-container">
          {affirmationButton({
            showButton: !isDesktop,
            disabled: disabilityOfMobileButton(),
            onClick: emiStepCompletionForMobile,
          })}
        </div>
      )}
    </div>
  );
};

export default EmiCardPayment;
